<template>
  <base-layout>
    <template v-slot:title> {{ $t("settings.title") }} - <slot name="title"></slot> </template>
    <template v-slot:content>
      <div class="flex gap-6">
        <div class="w-max-44 w-44">
          <nav class="sticky top-6">
            <div class="bg-white rounded-md shadow mb-4" v-for="menuGroup in menubar" v-bind:key="menuGroup">
              <ol>
                <li class="text-gray-900 px-3 py-2 text-sm font-bold border-b-2">
                  <div class="group flex items-center">
                    <h3 class="truncate w-full font-bold">{{ $t(menuGroup.name) }}</h3>
                  </div>
                </li>
                <li v-for="menuItem in menuGroup.menuGroup" v-bind:key="menuItem">
                  <router-link v-if="menuItem.routeName" :to="{ name: menuItem.routeName }" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 rounded-md group flex items-center px-3 py-2 text-sm font-medium">
                    <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span class="truncate w-full"> {{ $t(menuItem.name) }} </span>
                  </router-link>
                  <a class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900" v-else-if="menuItem.outgoingLink" :target="menuItem.outgoingLinkIsBlank ? '_blank' : '_self'" :href="menuItem.outgoingLink">
                    <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span class="truncate w-full"> {{ $t(menuItem.name) }} </span>
                  </a>
                  <div class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900" v-else>
                    <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    <span class="truncate w-full"> {{ $t(menuItem.name) }} </span>
                  </div>
                </li>
              </ol>
            </div>
          </nav>
        </div>
        <main class="w-full"><slot name="content"></slot></main>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/Default.vue";
export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      menubar: [
        {
          name: "settings.user.general.menubar.title",
          menuGroup: [
            {
              name: "settings.user.general.menubar.profile",
              routeName: "settings-user-general-profile",
            },
            {
              name: "settings.user.general.menubar.appearence",
              routeName: "settings-user-general-appearence",
            },
            {
              name: "settings.user.general.menubar.notifications",
              routeName: "settings-user-general-notifications",
            },
            {
              name: "settings.user.general.menubar.language",
              routeName: "settings-user-general-language",
            },
            {
              name: "settings.user.general.menubar.integrations",
              routeName: "settings-user-general-integrations",
            },
          ],
        },
        {
          name: "settings.user.security.menubar.title",
          menuGroup: [
            {
              name: "settings.user.security.menubar.user-security",
              routeName: "settings-user-security",
            },
            {
              name: "settings.user.security.menubar.password",
            },
            {
              name: "settings.user.security.menubar.user-security-log",
            },
            {
              name: "settings.user.security.menubar.gdpr",
              routeName: "settings-user-security-gdpr",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>

<!--
  Usage:
  
    <template>
      <layout></layout>
    </template>

    <script>
    import layout from "@/layouts/Login.vue";
    export default {
      components: {
        layout,
      },
    };
    </script>


-->
<!--

-->
